.w-15 {
    width: 15% !important;
}
.dropdown-menu-nav {
    transform: translate(0px, 5px) !important;
}

/*a.dropdown-item:hover {
    background-color: whitesmoke;
}
div.dropdown:hover {
    background-color: whitesmoke;
}*/

.dropdown-toggle::after {
    display: none !important;
}
.sidebar {
    height: 100%;
}
.icon-sidebar {
    height: calc(100vh - 61px);
    width: 63px;
}
.breadcrumb-divider-none {
    --bs-breadcrumb-divider: '';
}

.bd-sidebar {
    z-index:1 !important;
}
.offcanvas.offcanvas-start {
    top: unset !important;
    height: calc(100vh - 61px);
    -webkit-top: unset !important;
    -webkit-height: calc(100vh - 61px);
    width: 300px !important;
    overflow-y: auto;
    -webkit-overflow-y: auto;
    z-index: 4;
    -webkit-z-index: 4;
}
@media (max-width: 990px) {
    .offcanvas.offcanvas-start {
        top:unset !important;
    }
}
