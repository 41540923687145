/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Dubai';
    font-style: normal;
    font-weight: normal;
    src: local('Dubai Regular'), url('Dubai-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Dubai';
    font-style: normal;
    font-weight: 100;
    src: local('Dubai'), url('Dubai-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Dubai';
    font-style: normal;
    font-weight: 500;
    src: local('Dubai Medium'), url('Dubai-Medium.woff') format('woff');
    }


@font-face {
    font-family: 'Dubai';
    font-style: normal;
    font-weight: bold;
    src: local('Dubai Bold'), url('Dubai-Bold.woff') format('woff');
}