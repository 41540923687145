.table td,
.table th {
    white-space: nowrap;
}
.tableWrap-xl {
    max-height: 80vh;
    min-height: 70vh;
}
.tableWrap-bg {
    max-height: 70vh;
}
.tableWrap-md {
    max-height: 50vh;
    min-height: 50vh;
}
.tableWrap-sm {
    max-height: 70vh;
    min-height: 30vh;
}
.tableWrap-xs {
    max-height: 20vh !important;
}
.tableWrap table thead,
.tableWrap-bg table thead,
.tableWrap-md table thead {
    top: -1px;
    z-index: 1 !important;
}
@media (max-width: 767.98px) OR (max-width: 991.98px) {
    .tableWrap-sm {
        max-height: 30vh !important;
    }
}
@media (max-width: 1199.98px) {
    .tableWrap-lg {
        max-height: 70vh;
    }
}