.text-shadow {
    text-shadow: 0px 1px 2px #b7b7b7;
}
.min-vh-80 {
    min-height: 80vh;
}
.max-vh-80 {
    max-height: 80vh;
}
.form-label {
     margin-bottom: 0; 
}
/*Phone Input*/
.react-tel-input{
    /*z-index:6;*/
}

/*Phone Input*/
/*News bar Ticker*/
@keyframes tickerh {
    0% {
        transform: translate3d(100%,0 , 0);
    }

    100% {
        transform: translate3d(-10%, 0, 0);
    }
}

@keyframes tickerh-rtl {
    0% {
        transform: translate3d(-100%,0, 0);
    }

    100% {
        transform: translate3d(100%, 0, 0);
    }
}

.hmove {
    animation: tickerh linear 20s infinite;
}
    .hmove:hover {
        animation-play-state: paused;
    }
.hmove-rtl {
    animation: tickerh-rtl linear 20s infinite;
}
    .hmove-rtl:hover {
        animation-play-state: paused;
    }
/*News bar Ticker*/


/*images*/
.img-back {
    background-size: cover;
}
.img-back2 {
    background-size: cover;
}
.color-back {
    background-color: rgba(3, 48, 44, 0.75)
}
.left-image {
    background-size: cover;
}
/*images*/


/* Track */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track {
    background: #263238;
    border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #bcbcbc;
    border-radius: 5px;
}
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #8c8c8c;
    }

select:disabled, button:disabled, input:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}