@font-face {
  font-family: 'iconssc';
  src:  url('fonts/iconssc.eot?1f6m3g');
  src:  url('fonts/iconssc.eot?1f6m3g#iefix') format('embedded-opentype'),
    url('fonts/iconssc.ttf?1f6m3g') format('truetype'),
    url('fonts/iconssc.woff?1f6m3g') format('woff'),
    url('fonts/iconssc.svg?1f6m3g#iconssc') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconssc-"], [class*=" iconssc-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'iconssc' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1.5;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.iconssc-cancel:before {
  content: "\e968";
}
.iconssc-done-1:before {
  content: "\e96b";
}
.iconssc-minus-1:before {
  content: "\e96c";
}
.iconssc-off:before {
  content: "\e96d";
}
.iconssc-plus-1:before {
  content: "\e96e";
}
.iconssc-close-1:before {
  content: "\e96f";
}
.iconssc-auto:before {
  content: "\e967";
}
.iconssc-dark:before {
  content: "\e969";
}
.iconssc-light:before {
  content: "\e96a";
}
.iconssc-bitcoin:before {
  content: "\e964";
}
.iconssc-coins:before {
  content: "\e965";
}
.iconssc-gold:before {
  content: "\e966";
}
.iconssc-download-line:before {
  content: "\e95f";
}
.iconssc-search-line:before {
  content: "\e960";
}
.iconssc-scan-qr:before {
  content: "\e961";
}
.iconssc-send-line:before {
  content: "\e962";
}
.iconssc-upload-photo:before {
  content: "\e963";
}
.iconssc-eye-line:before {
  content: "\e955";
}
.iconssc-bell-line:before {
  content: "\e956";
}
.iconssc-copy-line:before {
  content: "\e957";
}
.iconssc-error-line:before {
  content: "\e958";
}
.iconssc-logout-line:before {
  content: "\e959";
}
.iconssc-home-line:before {
  content: "\e95a";
}
.iconssc-receipt-line:before {
  content: "\e95b";
}
.iconssc-eye-hide-line:before {
  content: "\e95c";
}
.iconssc-user-line:before {
  content: "\e95d";
}
.iconssc-star-line:before {
  content: "\e95e";
}
.iconssc-mobile-add:before {
  content: "\e932";
}
.iconssc-pin-code:before {
  content: "\e933";
}
.iconssc-done:before {
  content: "\e951";
}
.iconssc-verification-message:before {
  content: "\e952";
}
.iconssc-simcards:before {
  content: "\e953";
}
.iconssc-verification-code:before {
  content: "\e954";
}
.iconssc-book:before {
  content: "\e904";
}
.iconssc-home:before {
  content: "\e91f";
}
.iconssc-up-arrow-light:before {
  content: "\e905";
}
.iconssc-down-arrow-2:before {
  content: "\e90a";
}
.iconssc-left-arrow-light:before {
  content: "\e90e";
}
.iconssc-right-arrow-light:before {
  content: "\e915";
}
.iconssc-left-arrow:before {
  content: "\e916";
}
.iconssc-right-arrow:before {
  content: "\e922";
}
.iconssc-up-arrow:before {
  content: "\e92e";
}
.iconssc-down-arrow:before {
  content: "\e92f";
}
.iconssc-edit-1:before {
  content: "\e931";
}
.iconssc-edit:before {
  content: "\e917";
}
.iconssc-layers:before {
  content: "\e934";
}
.iconssc-menu:before {
  content: "\e935";
}
.iconssc-time:before {
  content: "\e940";
}
.iconssc-calculator:before {
  content: "\e941";
}
.iconssc-more:before {
  content: "\e946";
}
.iconssc-message-square:before {
  content: "\e907";
}
.iconssc-message-circle:before {
  content: "\e908";
}
.iconssc-remittances:before {
  content: "\e900";
}
.iconssc-accounting:before {
  content: "\e901";
}
.iconssc-exchange:before {
  content: "\e902";
}
.iconssc-bank:before {
  content: "\e903";
}
.iconssc-bookmark:before {
  content: "\e906";
}
.iconssc-chart:before {
  content: "\e947";
}
.iconssc-check:before {
  content: "\e909";
}
.iconssc-close:before {
  content: "\e90b";
}
.iconssc-minus:before {
  content: "\e90c";
}
.iconssc-plus:before {
  content: "\e90d";
}
.iconssc-info-square:before {
  content: "\e90f";
}
.iconssc-edit-2:before {
  content: "\e948";
}
.iconssc-key:before {
  content: "\e949";
}
.iconssc-cloud-1:before {
  content: "\e910";
}
.iconssc-cloud-2:before {
  content: "\e911";
}
.iconssc-cloud:before {
  content: "\e912";
}
.iconssc-compliance:before {
  content: "\e913";
}
.iconssc-copy:before {
  content: "\e914";
}
.iconssc-document:before {
  content: "\e94a";
}
.iconssc-document-delete:before {
  content: "\e94b";
}
.iconssc-document-add:before {
  content: "\e94c";
}
.iconssc-export:before {
  content: "\e918";
}
.iconssc-calender:before {
  content: "\e919";
}
.iconssc-eye-hide:before {
  content: "\e91a";
}
.iconssc-eye:before {
  content: "\e91b";
}
.iconssc-flag:before {
  content: "\e91c";
}
.iconssc-folder:before {
  content: "\e91d";
}
.iconssc-graph:before {
  content: "\e91e";
}
.iconssc-add-user:before {
  content: "\e920";
}
.iconssc-user-group:before {
  content: "\e921";
}
.iconssc-user:before {
  content: "\e923";
}
.iconssc-management:before {
  content: "\e94d";
}
.iconssc-lock-check:before {
  content: "\e924";
}
.iconssc-lock:before {
  content: "\e925";
}
.iconssc-lock-open:before {
  content: "\e926";
}
.iconssc-print:before {
  content: "\e927";
}
.iconssc-save:before {
  content: "\e928";
}
.iconssc-trash:before {
  content: "\e929";
}
.iconssc-settings:before {
  content: "\e92a";
}
.iconssc-shield:before {
  content: "\e92b";
}
.iconssc-shield-1:before {
  content: "\e92c";
}
.iconssc-shield-2:before {
  content: "\e92d";
}
.iconssc-update:before {
  content: "\e936";
}
.iconssc-upload:before {
  content: "\e937";
}
.iconssc-download:before {
  content: "\e938";
}
.iconssc-login:before {
  content: "\e939";
}
.iconssc-logout:before {
  content: "\e93a";
}
.iconssc-zoom-in:before {
  content: "\e93b";
}
.iconssc-zoom-out:before {
  content: "\e94e";
}
.iconssc-search:before {
  content: "\e93c";
}
.iconssc-mail:before {
  content: "\e93d";
}
.iconssc-send:before {
  content: "\e93e";
}
.iconssc-link:before {
  content: "\e93f";
}
.iconssc-heart:before {
  content: "\e942";
}
.iconssc-scanner:before {
  content: "\e943";
}
.iconssc-star:before {
  content: "\e944";
}
.iconssc-bell:before {
  content: "\e945";
}
.iconssc-share:before {
  content: "\e94f";
}
.iconssc-language:before {
  content: "\e950";
}
